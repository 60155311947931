import * as React from "react"
import { graphql } from 'gatsby'
import SbEditable from 'storyblok-react'

import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicComponent from "../components/dynamicComponent"
import useStoryblok from "../lib/storyblok"

const BlogOverview = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.body.map(blok => {
    return (<DynamicComponent blok={blok} key={blok._uid} />)
  })

  return (
  <Layout>
    <SbEditable content={story.content}>
      <Seo
        title={`Blog ${ story.name }`}
        description="I'm a DevRel Engineer at Storyblok. Also, I organize a free tech skill and knowledge-sharing community, Lilac."
        image={ data.site.image }
      />
        <div className="py-14">
          <h1
            className="header-gradetion hero-headline text-5xl font-black text-center pt-3 pb-10"
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
          >{ story.content.title }</h1>
        </div>
        { components }
    </SbEditable>
  </Layout>
)}

export default BlogOverview

export const query = graphql`
  query BlogPostsQuery {
    storyblokEntry(full_slug: {eq: "blog/"}) {
      content
      name
    }
    site {
      siteMetadata {
        image
      }
    }
  }
`